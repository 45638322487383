<template>
  <div class="school">
    <Navigation :path="path" />
    <img src="~img/school/01.png" />
    <div class="one">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>小学阶段课程</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <div class="title">
        专注 6-12 岁以创意思考力为基础的科学·思维融合教育课程
      </div>
      <div class="hidden-xs-only">
        <img src="~img/school/02.png" class="pc-biaoge" />
      </div>
      <div class="hidden-sm-and-up">
        <img src="~img/school/02.png" class="web-biaoge" />
      </div>
      <div class="one-bt">
        <img src="~img/school/03.png" alt="" />
        <div class="pc-text hidden-xs-only">
          <p>
            爱因斯坦思维科学馆的第一步从 WHY 开始<br />
            “为什么我们的孩子很难具备良好的科学思维能力？”<br />
            对于这个问题，我们找出来的答案是<br />
            <span>“让孩子进行创造性探索式的学习”</span>
          </p>
        </div>
        <div class="web-text hidden-sm-and-up">
          <p>
            爱因斯坦思维科学馆的第一步从 WHY 开始
            “为什么我们的孩子很难具备良好的科学思维能力？”
            对于这个问题，我们找出来的答案是<br />
            <span>“让孩子进行创造性探索式的学习”</span>
          </p>
        </div>
      </div>
    </div>
    <div class="two">
      <div class="title-box">
        <img src="~img/school/05.png" class="title-icon" />
        <h4>科学·思维融合教育</h4>
        <img src="~img/school/06.png" class="title-icon" />
      </div>
      <h5>
        爱因斯坦思维科学馆通过建构主义哲学和探索式学习，配合课堂中的发表和讨论、协同学习等方法引导学生进入科学·思维的世界。
      </h5>
      <ul>
        <li v-for="item in list" :key="item.id">
          <img :src="item.image" />
          <p class="p2">{{item.title}}</p>
          <div class="fenge"></div>
          <p class="p3">
            {{item.abstract}}
          </p>
        </li>
      </ul>
    </div>
    <div class="three">
      <div class="title-box">
        <img src="~img/school/05.png" class="title-icon" />
        <h4>科学·思维融合课程</h4>
        <img src="~img/school/06.png" class="title-icon" />
      </div>
      <ul>
        <li>
          <img src="~img/school/08.png" />
        </li>
        <li>
          <img src="~img/school/09.png" />
        </li>
        <li>
          <img src="~img/school/10.png" />
        </li>
      </ul>
    </div>
    <div class="four">
      <div class="title-box">
        <img src="~img/school/05.png" class="title-icon" />
        <h4>特色项目主题活动</h4>
        <img src="~img/school/06.png" class="title-icon" />
      </div>
      <div class="text-box">
        <p>发散思维、举一反三、触类旁通</p>
        <p>
          将爱因斯坦教育理念融入课堂，教会孩子观察分析、探索思考、探知真理，做驾驭学习的小天才!
        </p>
      </div>
      <img src="~img/school/11.png" class="time-img" />
      <ul>
        <li>
          <img src="~img/school/12.png" />
          <p class="p1">项目理解</p>
          <p class="p2">
            团队合作+主题学习组成一个项目执行小组，与小组成员一起选择主题，并掌握解决问题所需的基础知识。
          </p>
        </li>
        <li>
          <img src="~img/school/13.png" />
          <p class="p1">项目企划</p>
          <p class="p2">
            树立计划+付诸实践，一起解决问题并完成实验。在项目执行过程中整理所需要的所有资料。
          </p>
        </li>
        <li>
          <img src="~img/school/14.png" />
          <p class="p1">项目执行</p>
          <p class="p2">
            创意导出+项目设计，为项目执行制定具体计划，与同组的朋友讨论后决定，并精心安排整个项目日程。
          </p>
        </li>
        <li>
          <img src="~img/school/15.png" />
          <p class="p1">项目评价</p>
          <p class="p2">
            结果整理+模拟发表，检查项目流程，分配角色，模拟发表后，与队友进行评价及反省，发扬优势，弥补不足。
          </p>
        </li>
      </ul>
    </div>
    <div class="five">
      <div class="title-box">
        <img src="~img/school/20.png" class="title-icon" />
        <h4>快快加入<span>爱因斯坦思维科学馆</span>吧！</h4>
        <img src="~img/school/21.png" class="title-icon" />
      </div>
      <p>
        爱因斯坦思维科学馆，通过创意思考能力诊断测评，诊断孩子的学习能力和取向之后，通过专业的顾问咨询的形式为您推荐最适合您孩子的项目。也可以立刻预约公开体验课来体验我们独一无二的科学·思维融合课程。
      </p>
      <ul>
        <li>
          <img src="~img/school/17.png" alt="" />
          <p>多维能力测试&公开课</p>
        </li>
        <li>
          <img src="~img/school/18.png" alt="" />
          <p>来中心体验</p>
        </li>
        <li>
          <img src="~img/school/19.png" alt="" />
          <p>专家顾问解答</p>
        </li>
      </ul>
    </div>
    <consulting />
    <foot />
  </div>
</template>

<script>
import Navigation from "@/components/header.vue";
import footer from "@/components/footer.vue";
import consulting from "@/components/consulting.vue";

export default {
  components: {
    Navigation,
    foot: footer,
    consulting,
  },
  data() {
    return {
      path: "/school",
      list: [],
    };
  },
  created() {
    this.getThought();
  },
  methods: {
    async getThought() {
      const res = await this.axios.get("api/index/thought");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        console.log(data);
        this.list = data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.school {
  .title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .title-icon {
      width: 0.8rem;
      height: 0.38rem;
    }
    h4 {
      font-size: 0.45rem;
      line-height: 0.8rem;
      margin-left: 0.47rem;
      margin-right: 0.47rem;
    }
  }
  .one {
    padding-top: 1.07rem;
    .title {
      max-width: 7.69rem;
      height: 0.51rem;
      margin: auto;
      margin-top: 0.41rem;
      background: #ffe968;
      border-radius: 0.51rem;
      text-align: center;
      line-height: 0.51rem;
      color: #3b73ce;
      font-size: 0.27rem;
    }
    .pc-biaoge {
      width: 13.3rem;
      margin: auto;
      margin-top: 0.5rem;
    }
    .web-biaoge {
      width: 95%;
      margin: auto;
      margin-top: 0.5rem;
    }
    .one-bt {
      margin-top: 2.29rem;
      position: relative;
      .pc-text {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        p {
          font-size: 0.35rem;
          line-height: 0.76rem;
          color: #3e3a3a;
          span {
            color: #4f82e1;
          }
        }
      }
      .web-text {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        p {
          font-size: 0.2rem;
          line-height: 0.4rem;
          color: #3e3a3a;
          span {
            color: #4f82e1;
          }
        }
      }
    }
  }
  .two {
    padding-top: 1.43rem;
    h5 {
      width: 62%;
      margin: auto;
      font-size: 0.25rem;
      text-align: center;
      margin-top: 0.56rem;
      line-height: 0.46rem /* 46/100 */;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 1rem /* 100/100 */;
      // padding: 0 2.62rem 2.67rem 0;
      padding-left: 2.62rem /* 262/100 */;
      padding-right: 2.67rem /* 267/100 */;
      li {
        width: 3.84rem /* 384/100 */;
        margin-bottom: 0.9rem /* 90/100 */;
        img {
          margin-bottom: 0.35rem /* 35/100 */;
          border-radius: 0.15rem /* 15/100 */;
        }
        p {
          padding-left: 0.1rem /* 40/100 */;
        }
        .p2 {
          font-size: 0.29rem /* 25/100 */;
          color: #4f82e1;
          margin-bottom: 0.1rem /* 10/100 */;
        }
        .p3 {
          font-size: 0.2rem /* 20/100 */;
          color: #373737;
          line-height: 0.35rem /* 35/100 */;
        }
        .fenge {
          width: 0.56rem /* 56/100 */;
          height: 0.09rem /* 9/100 */;
          background: #ffe968;
          margin-left: 0.1rem /* 10/100 */;
          margin-bottom: 0.2rem /* 20/100 */;
        }
      }
    }
    @media (max-width: 750px) {
      ul {
        padding-left: 20px;
        padding-right: 20px;
        li {
          width: 80%;
          margin: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
  .three {
    background: #eee;
    margin-top: 1.82rem /* 182/100 */;
    padding-top: 0.92rem /* 92/100 */;
    padding-top: 1rem /* 100/100 */;
    ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 1.12rem /* 112/100 */;
      padding-left: 0.5rem /* 50/100 */;
      padding-right: 0.5rem /* 50/100 */;
      li {
        margin-bottom: 0.6rem /* 40/100 */;
      }
    }
    @media (max-width: 750px) {
      ul {
        padding: 0;
        li {
          width: 80%;
          margin: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
  .four {
    padding-top: 1.11rem /* 111/100 */;
    .text-box {
      max-width: 11.57rem /* 1157/100 */;
      padding: 0.1rem /* 10/100 */;
      margin: auto;
      text-align: center;
      font-size: 0.25rem /* 25/100 */;
      color: #4f82e1;
      background: #dce7fc;
      border-radius: 0.88rem /* 88/100 */;
      line-height: 0.4rem /* 40/100 */;
      margin-top: 0.33rem /* 33/100 */;
    }
    .time-img {
      max-width: 7.15rem /* 715/100 */;
      margin: auto;
      margin-top: 0.48rem /* 48/100 */;
    }
    ul {
      padding-left: 2.9rem /* 292/100 */;
      padding-right: 2.9rem /* 291/100 */;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 0.47rem /* 47/100 */;
      li {
        width: 6.52rem /* 652/100 */;
        margin-bottom: 0.7rem /* 60/100 */;
        .p1 {
          font-size: 0.3rem /* 30/100 */;
          background: url("~img/school/16.png") no-repeat;
          margin-top: 0.22rem /* 22/100 */;
          margin-bottom: 0.15rem /* 22/100 */;
          padding-left: 0.19rem; /* 19/100 */
        }
        .p2 {
          font-size: 0.2rem /* 20/100 */;
          line-height: 0.38rem /* 38/100 */;
          padding-left: 0.19rem; /* 19/100 */
          color: #666666;
        }
      }
    }
    @media (max-width: 750px) {
      ul {
        padding: 0;
        li {
          width: 80%;
          margin: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
  .five {
    background: #eee;
    padding-top: 0.91rem /* 91/100 */;
    padding-bottom: 1.31rem /* 131/100 */;
    .title-box {
      span {
        color: #4f82e1;
      }
      img {
        width: 1.28rem;
        height: 1.26rem;
      }
    }

    p {
      max-width: 12.74rem /* 1274/100 */;
      margin: auto;
      margin-top: 0.28rem /* 28/100 */;
      font-size: 0.25rem /* 25/100 */;
      line-height: 0.46rem /* 46/100 */;
      color: #666666;
    }
    ul {
      display: flex;
      justify-content: space-between;
      padding-left: 3.58rem /* 358/100 */;
      padding-right: 3.91rem /* 391/100 */;
      margin-top: 0.97rem /* 97/100 */;
      flex-wrap: wrap;
      li {
        img {
          width: 1.67rem /* 167/100 */;
          margin: auto;
        }
        p {
          text-align: center;
          color: #4f82e1;
        }
      }
    }
    @media (max-width: 750px) {
      ul {
        padding: 0;
        li {
          width: 80%;
          margin: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>